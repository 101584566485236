// Основні кольори
$gray-dark: #252525;
$gray: #949494;
$white: #fff;
$black: #000;
$swiper-theme-color: #fff;
$swiper-pagination-bullet-vertical-gap: 10px;
$bs-blue: #0d6efd;
$bs-indigo: #6610f2;
$bs-purple: #6f42c1;
$bs-pink: #d63384;
$bs-red: #dc3545;
$bs-orange: #fd7e14;
$bs-yellow: #ffc107;
$bs-green: #198754;
$bs-teal: #20c997;
$bs-cyan: #0dcaf0;
$bs-white: #fff;
$bs-gray: #6c757d;
$bs-gray-dark: #343a40;
$bs-gray-100: #f8f9fa;
$bs-gray-200: #e9ecef;
$bs-gray-300: #dee2e6;
$bs-gray-400: #ced4da;
$bs-gray-500: #adb5bd;
$bs-gray-600: #6c757d;
$bs-gray-700: #495057;
$bs-gray-800: #343a40;
$bs-gray-900: #212529;
$bs-primary: #0f1921;
$bs-secondary: #172027;
$bs-success: #198754;
$bs-info: #0dcaf0;
$bs-warning: #b1740f;
$bs-danger: #dc3545;
$bs-light: #fff;
$bs-dark: #212529;
$bs-primary-rgb: 15,25,33;
$bs-secondary-rgb: 23,32,39;
$bs-success-rgb: 25,135,84;
$bs-info-rgb: 13,202,240;
$bs-warning-rgb: 177,116,15;
$bs-danger-rgb: 220,53,69;
$bs-light-rgb: 255,255,255;
$bs-dark-rgb: 33,37,41;
$bs-white-rgb: 255,255,255;
$bs-black-rgb: 0,0,0;
$bs-body-color-rgb: 255,255,255;
$bs-body-bg-rgb: 15,25,33;
$bs-font-sans-serif: "Urbanist",sans-serif;
$bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
$bs-gradient: linear-gradient(180deg,hsla(0,0%,100%,0.15),hsla(0,0%,100%,0));
$bs-body-font-family: var($bs-font-sans-serif);
$bs-body-font-size: 1rem;
$bs-body-font-weight: 400;
$bs-body-line-height: 1.5;
$bs-body-color: #fff;
$bs-body-bg: #0f1921;

// Змінні Bootstrap 5
$primary: $gray-dark;
$secondary: $gray;
$light: $white;
$dark: $black;

// Змінні для заголовків
$header-font-family: 'Montserrat', sans-serif;
$header-font-weight: 700;
$header-color: $white;

// Змінні для фону блоків
$block-bg-light: $white;
$block-bg-dark: $gray-dark;

// Змінні для тіла
$body-bg: $black;
$body-color: $light;

@import "bootstrap/scss/bootstrap";
@import "../../node_modules/animate.css/animate.min.css";
@import "../../node_modules/lightbox2/dist/css/lightbox.min.css";
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

:root {
  --swiper-theme-color: #fff;
  --swiper-pagination-bullet-vertical-gap: 10px;
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0f1921;
  --bs-secondary: #172027;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #b1740f;
  --bs-danger: #dc3545;
  --bs-light: #fff;
  --bs-dark: #212529;
  --bs-primary-rgb: 15,25,33;
  --bs-secondary-rgb: 23,32,39;
  --bs-success-rgb: 25,135,84;
  --bs-info-rgb: 13,202,240;
  --bs-warning-rgb: 177,116,15;
  --bs-danger-rgb: 220,53,69;
  --bs-light-rgb: 255,255,255;
  --bs-dark-rgb: 33,37,41;
  --bs-white-rgb: 255,255,255;
  --bs-black-rgb: 0,0,0;
  --bs-body-color-rgb: 255,255,255;
  --bs-body-bg-rgb: 15,25,33;
  --bs-font-sans-serif: "Urbanist",sans-serif;
  --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient: linear-gradient(180deg,hsla(0,0%,100%,0.15),hsla(0,0%,100%,0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #fff;
  --bs-body-bg: #0f1921;
}

*,:after,:before {
  box-sizing: border-box
}

@media(prefers-reduced-motion:no-preference) {
  :root {
    scroll-behavior: smooth
  }
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  margin: 0;
  text-align: var(--bs-body-text-align)
}

hr {
  background-color: currentColor;
  border: 0;
  color: inherit;
  margin: 1rem 0;
  opacity: .25
}

hr:not([size]) {
  height: 1px
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: .5rem;
  margin-top: 0
}

.h1,h1 {
  font-size: calc(1.375rem + 1.5vw)
}

@media(min-width: 1200px) {
  .h1,h1 {
    font-size:2.5rem
  }
}

.h2,h2 {
  font-size: calc(1.325rem + .9vw)
}

@media(min-width: 1200px) {
  .h2,h2 {
    font-size:2rem
  }
}

.h3,h3 {
  font-size: calc(1.3rem + .6vw)
}

@media(min-width: 1200px) {
  .h3,h3 {
    font-size:1.75rem
  }
}

.h4,h4 {
  font-size: calc(1.275rem + .3vw)
}

@media(min-width: 1200px) {
  .h4,h4 {
    font-size:1.5rem
  }
}

.h5,h5 {
  font-size: 1.25rem
}

.h6,h6 {
  font-size: 1rem
}

p {
  margin-bottom: 1rem;
  margin-top: 0
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
  hyphens: auto;
  text-transform: uppercase;
  word-break: normal
}

.social-media-icons > li > .nav-link {
  color: $white;
  display: block;
  padding: .5rem 1rem;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
  .nav-link {
    transition: none
  }
}

.social-media-icons > li > .nav-link:focus, .social-media-icons > li > .nav-link:hover {
  color: #b1740f
}

.social-media-icons .dashicons {
  font-size: 34px
}

.blackash-block-eventgrid .h1,.blackash-block-eventgrid .h2,.blackash-block-eventgrid .h3,.blackash-block-eventgrid h1,.blackash-block-eventgrid h2,.blackash-block-eventgrid h3,.blackash-heading,.is-style-blackash-heading {
  margin-bottom: 3rem;
  position: relative
}

.blackash-block-eventgrid .h1:after,.blackash-block-eventgrid .h2:after,.blackash-block-eventgrid .h3:after,.blackash-block-eventgrid h1:after,.blackash-block-eventgrid h2:after,.blackash-block-eventgrid h3:after,.blackash-heading:after,.is-style-blackash-heading:after {
  border-top: 2px solid #fff;
  content: " ";
  display: block;
  margin-top: 1rem;
  position: absolute;
  width: 100px
}

.blackash-block-eventgrid .has-text-align-center.h1:after,.blackash-block-eventgrid .has-text-align-center.h2:after,.blackash-block-eventgrid .has-text-align-center.h3:after,.blackash-block-eventgrid .text-center.h1:after,.blackash-block-eventgrid .text-center.h2:after,.blackash-block-eventgrid .text-center.h3:after,.blackash-block-eventgrid h1.has-text-align-center:after,.blackash-block-eventgrid h1.text-center:after,.blackash-block-eventgrid h2.has-text-align-center:after,.blackash-block-eventgrid h2.text-center:after,.blackash-block-eventgrid h3.has-text-align-center:after,.blackash-block-eventgrid h3.text-center:after,.blackash-heading.has-text-align-center:after,.blackash-heading.text-center:after,.is-style-blackash-heading.has-text-align-center:after,.is-style-blackash-heading.text-center:after {
  left: 50%;
  transform: translateX(-50%)
}

.blackash-block-eventgrid .has-text-align-right.h1:after,.blackash-block-eventgrid .has-text-align-right.h2:after,.blackash-block-eventgrid .has-text-align-right.h3:after,.blackash-block-eventgrid .text-end.h1:after,.blackash-block-eventgrid .text-end.h2:after,.blackash-block-eventgrid .text-end.h3:after,.blackash-block-eventgrid h1.has-text-align-right:after,.blackash-block-eventgrid h1.text-end:after,.blackash-block-eventgrid h2.has-text-align-right:after,.blackash-block-eventgrid h2.text-end:after,.blackash-block-eventgrid h3.has-text-align-right:after,.blackash-block-eventgrid h3.text-end:after,.blackash-heading.has-text-align-right:after,.blackash-heading.text-end:after,.is-style-blackash-heading.has-text-align-right:after,.is-style-blackash-heading.text-end:after {
  left: auto;
  right: 0
}

.text-center .blackash-heading:after {
  left: 50%;
  transform: translateX(-50%)
}

.text-end .blackash-heading:after {
  left: auto;
  right: 0
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: $body-bg;
  color: $body-color;
}


h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
}

p {
  font-family: 'Roboto', sans-serif;
}

.sedan {
  padding-top: 2rem;
}

.img-car-icon {
  height: 12rem;
  padding-bottom: 2rem;
}

.project-item {
  text-align: center;
}

// Налаштування фону блоків
.block-light {
  background-color: $block-bg-light;
}

.block-dark {
  background-color: $block-bg-dark;
}

#map {
  min-height: 30rem !important;
}

.navbar-brand {
  padding: 0 !important;
}

.navbar-brand:after {
  padding: 0 !important;
}

.btn-square {
  padding: 0.5rem 1rem;
  border: 2px solid #fff;
  border-radius: 0;

  &:hover {
    border-color: #b1740f;
    background-color: #fff;
    color: #b1740f
  }
}

.btn-square-primary {
  background-color: #252525;
  color: #fff;

  &:hover {
    border-color: #b1740f;
    background-color: #fff;
    color: #b1740f
  }
}

.blackash-block-info>.container {
  border: 2px solid #fff
}

.blackash-block-info .separator {
  border-right: 2px solid #fff
}

.blackash-block-info .block__image {
  height: 100%;
  object-fit: cover;
  position: relative;
  transform-origin: left center;
  width: 100%;
  z-index: -1
}

.blackash-block-info .block__image img {
  height: 100%!important;
  object-fit: cover;
  width: 100%
}

.block .block__image img {
  height: auto;
  max-width: 100%
}

.blackash-block-fullwidth {
  background-position: 50%;
  background-size: cover
}

.blackash-block-fullwidth .bg-dark-transparent {
  padding: 5rem
}

@media(max-width: 575.98px) {
  .blackash-block-fullwidth .bg-dark-transparent {
    margin:0;
    padding: 4rem 2rem
  }
}

.blackash-block-dualimage {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff
}

.blackash-block-dualimage .block__image {
  position: relative
}

.blackash-block-dualimage .blackash-foreground-image {
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(-27%,18%) scale(.7);
  z-index: 1
}

.bg-secondary .blackash-block-quote:after,.bg-secondary .blackash-block-quote:before {
  background: #172027
}

#pagehero .blackash-block-quote {
  z-index: 2
}

#pagehero .blackash-block-quote:after,#pagehero .blackash-block-quote:before {
  background: transparent
}

.blackash-block-quote {
  font-size: 2rem;
  margin: 6rem 0;
  padding: 6rem 0;
  position: relative;
  text-align: center
}

@media(max-width: 575.98px) {
  .blackash-block-quote {
    font-size:1.6rem;
    margin: 3rem 0;
    padding: 1rem
  }
}

.blackash-block-quote p:last-of-type {
  margin-bottom: 0
}

.blackash-block-quote:before {
  background: #0f1921;
  content: "â€œ";
  display: block;
  font-family: Times New Roman;
  font-size: 128px;
  height: 60px;
  left: 50%;
  line-height: 1;
  position: relative;
  top: 0;
  transform: translateX(-50%);
  z-index: 1
}

@media(max-width: 575.98px) {
  .blackash-block-quote:before {
    position:relative
  }
}

.blackash-block-quote:after {
  background: #0f1921;
  bottom: 0;
  content: "â€";
  display: block;
  font-family: Times New Roman;
  font-size: 128px;
  height: 60px;
  left: 50%;
  line-height: 1;
  position: relative;
  right: 70px;
  transform: translateX(-50%);
  z-index: 1
}

@media(max-width: 575.98px) {
  .blackash-block-quote:after {
    position:relative
  }
}

.blackash-block-reviews .blackash-review-scroller-container {
  position: relative
}

.blackash-block-reviews .blackash-review-scroller-container:after {
  background: #0f1921;
  background: linear-gradient(90deg,rgba(15,25,33,0),#0f1921);
  content: " ";
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 350px;
  z-index: 1
}

.blackash-block-reviews .blackash-review-scroller {
  -ms-overflow-style: none;
  margin-right: 0;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  scrollbar-width: none
}

.blackash-block-reviews .blackash-review-scroller::-webkit-scrollbar {
  display: none
}

.blackash-block-reviews .blackash-review-scroller .blackash-review-col {
  width: 350px
}

.blackash-block-events .blackash-event-image {
  height: 250px;
  overflow: hidden;
  position: relative
}

.blackash-block-events .blackash-event-image img {
  height: 100%;
  object-fit: cover;
  opacity: .5;
  position: absolute;
  width: 100%;
  z-index: -1
}

.blackash-block-events .blackash-event-button {
  margin-top: -20px;
  position: relative;
  z-index: 1
}

.blackash-block-events .swiper-button-next,.blackash-block-events .swiper-button-prev {
  top: 130px
}

.blackash-block-margin {
  margin-bottom: 8rem
}

@media(max-width: 1199.98px) {
  .blackash-block-margin {
    margin-bottom:4.8rem
  }
}

@media(max-width: 575.98px) {
  .blackash-block-margin {
    margin-bottom:2.4rem
  }
}

.blackash-block-margin:nth-of-type(0) {
  margin-top: 0
}

.blackash-block-margin-half {
  margin-bottom: 4rem
}

@media(max-width: 1199.98px) {
  .blackash-block-margin-half {
    margin-bottom:2.4rem
  }
}

@media(max-width: 575.98px) {
  .blackash-block-margin-half {
    margin-bottom:1.2rem
  }
}

.blackash-block-margin-half:nth-of-type(0) {
  margin-top: 0
}

.blackash-block-padding-secondary {
  padding: 4rem 0
}

@media(max-width: 1199.98px) {
  .blackash-block-padding-secondary {
    padding:2.4rem 0
  }
}

@media(max-width: 575.98px) {
  .blackash-block-padding-secondary {
    padding:2rem 0
  }
}

#page>.blackash-block-margin {
  margin-top: 0
}

.blackash-block-foodmenu .blackash-food-entry {
  border-bottom: 1px dashed #fff
}

.has-empty-entry .entry-content {
  margin-top: -8rem
}

.blackash-block-gallery-alt img,.blackash-block-gallery img {
  filter: brightness(.7);
  transition: all .5s
}

.blackash-block-gallery-alt img:hover,.blackash-block-gallery img:hover {
  filter: brightness(1);
  transform: scale(1.05)
}

.blackash-block-gallery-alt .blackash-block-gallery-link,.blackash-block-gallery .blackash-block-gallery-link {
  position: relative
}

.blackash-block-gallery-alt .blackash-block-gallery-link img,.blackash-block-gallery .blackash-block-gallery-link img {
  cursor: pointer
}

.blackash-block-gallery-alt .blackash-block-gallery-link:has(a:hover) img,.blackash-block-gallery .blackash-block-gallery-link:has(a:hover) img {
  filter: brightness(1);
  transform: scale(1.05)
}

.blackash-block-gallery-alt .blackash-block-gallery-link>a,.blackash-block-gallery .blackash-block-gallery-link>a {
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 2
}

.blackash-block-icons .blackash-block-icon .dashicons {
  font-size: 2.5rem!important;
  margin-left: -14px
}

.blackash-block-icons .swiper-button-next,.blackash-block-icons .swiper-button-prev {
  margin-top: -48px
}

.blackash-negative-margin-bottom {
  margin-bottom: -3rem
}

.blackash-negative-margin-top {
  margin-top: -8rem
}

@media(max-width: 1199.98px) {
  .blackash-negative-margin-top {
    margin-top:-4.8rem
  }
}

@media(max-width: 767.98px) {
  .blackash-negative-margin-top {
    margin-top:0
  }
}

.blackash-negative-margin-top .blackash-block-map .map {
  margin-bottom: -6px
}

.blackash-block-eventgrid .blashash-background-image {
  filter: brightness(.7);
  height: 100%;
  object-fit: cover
}

.no-underline {
  text-decoration: none;
}


